import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { IPayPalConfig, IOnApproveCallbackData, IOnClickCallbackActions } from 'ngx-paypal';

import { SurveyStorageService } from 'src/app/services/survey-storage.service';
import { AnonymizeService } from 'src/app/services/anonymize.service';
import { Globals } from 'src/app/services/globals.service';

import { PayPalConfig } from 'src/app/models/paypal-config';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  private errorOccurred: boolean;
  private errorMessage: string;
  private successPaymentUrl = 'https://krisen-check.de/vielen-dank/';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private surveyStorageService: SurveyStorageService,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private anonymizeService: AnonymizeService,
    public globals: Globals,
    private spinnerService: SpinnerService
  ) {
    this.paymentForm = this.formBuilder.group({
      customerEmail: [null, Validators.compose([Validators.required, Validators.email])],
      discountCode: [null],
      termsOfUse: [false, Validators.compose([Validators.requiredTrue])],
      privacyPolicy: [false, Validators.compose([Validators.requiredTrue])],
      declarationCancelation: [false, Validators.compose([Validators.requiredTrue])]
    });
  }

  public payPalConfig?: IPayPalConfig;
  private surveyId: string;
  paymentForm: FormGroup;

  ngOnInit() {
    this.surveyId = this.route.snapshot.parent.params.surveyId;
    this.http.get('api/payment/get-paypal-config').subscribe((config: PayPalConfig) => this.initConfig(config));
  }

  private initConfig(config: PayPalConfig): void {
    this.payPalConfig = {
      clientId: config.clientId,
      currency: config.currency,
      // for creating orders (transactions) on server see
      // https://developer.paypal.com/docs/checkout/reference/server-integration/set-up-transaction/
      createOrderOnServer: this.createOrderOnServer.bind(this),
      onApprove: this.captureOrderOnServer.bind(this),
      onCancel: this.onCancel.bind(this),
      onError: this.onError.bind(this)
    };
  }

  private createOrderOnServer(data): Promise<string> {
    return fetch('/api/payment/order/create/', {
      method: 'post',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        surveyId: this.surveyId,
        discountCode: this.paymentForm.controls.discountCode.value,
        customerEmail: this.paymentForm.controls.customerEmail.value,
        persistedSurvey: this.surveyStorageService.getPersistedSurvey(this.surveyId),
        timeZoneOffset: new Date().getTimezoneOffset()
      })
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        } else if (res.status == 400) {
          this.errorMessage = 'Dieser Gutscheincode ist leider nicht funktionsfähig.';
          this.errorOccurred = true;
          throw new Error('Discount code error');
        } else {
          this.errorMessage = 'Zahlung konnte nicht durchgeführt werden.';
          this.errorOccurred = true;
          throw new Error('Something went wrong..');
        }
      })
      .then(order => order.id)
      .catch(error => {
      });
  }

  private captureOrderOnServer(data: IOnApproveCallbackData, actions) {
    this.spinnerService.show("Zahlung in Bearbeitung");
    return fetch('/api/payment/order/capture', {
      method: 'post',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        orderID: data.orderID
      })
    }).then((res) => {
      if (res.ok) {
        this.localStorageService.clearStorage();
        this.navigateToSuccessPage();
        // this.paymentProcessing = false;
      } else {
        res.text().then(text => {
          this.errorOccurred = true;
          const errorRes = JSON.parse(text);
          if (errorRes.message == 'MAIL-FAILED') {
            this.errorMessage = 'Zahlung erfolgreich ausgeführt, die E-Mail konnte jedoch nicht zugestellt werden.';
          } else {
            this.errorMessage = 'Zahlung konnte nicht durchgeführt werden.';
          }
          this.spinnerService.hide();
        });
      }
    });
  }

  private navigateToSuccessPage() {
    // maybe we can use this to preload the page: <!-- <link rel="prerender" href="https://stabilitätscheck.de/danke/"> -->
    window.location.href = this.successPaymentUrl;
  }

  private onCancel(data, actions) {
    return this.http.post(`api/payment/order/cancel`, data).subscribe();
  }

  private onError(err) {
    // handle errors
  }

  private onClick(data, actions: IOnClickCallbackActions) {
    // return actions.reject();
  }
}
