import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  constructor(private spinnerService: SpinnerService) { }

  public showSpinner = false;

  public spinnerMessage = '';

  public show(spinnerMessage: string) {
    this.spinnerMessage = spinnerMessage;
    this.showSpinner = true;
  }

  public hide() {
    this.showSpinner = false;
  }

  ngOnInit() {
    this.spinnerService.showSpinnerSubject.subscribe(message  => this.show(message));
    this.spinnerService.hideSpinnerSubject.subscribe(() => this.hide());
  }

}
