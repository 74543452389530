import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/services/report.service';
import { AnnualReport } from 'src/app/models/report';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  constructor(private _reportService: ReportService) { }

  public reports: Observable<AnnualReport[]>;

  ngOnInit() {
    this.reports = this._reportService.getAnnualReport();
  }

}
