import { Injectable } from '@angular/core';
import { ErrorNotification } from '../models/error-notification';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorNotificationService {

  private errorNotificationSubject: Subject<ErrorNotification> = new Subject();

  constructor() { }

  public emitErrorNotification(error: ErrorNotification) {
    this.errorNotificationSubject.next(error);
  }

  get errorNotifications() {
    return this.errorNotificationSubject.asObservable();
  }
}
