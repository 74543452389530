import { Injectable } from '@angular/core';
import {
  CanDeactivate,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { ChapterComponent } from '../components/chapter/chapter.component';
import { Observable, of, Subject, from, BehaviorSubject } from 'rxjs';
import { SurveyStorageService } from './survey-storage.service';
import { map, take, switchMap, first, tap} from 'rxjs/operators';
import { SurveyService } from './survey.service';
import { Survey } from '../models/survey';

@Injectable()
export class ChapterRouteService implements CanDeactivate<ChapterComponent> {
  constructor(private chapterStorageService: SurveyStorageService, private surveyService: SurveyService) {}

  canDeactivate(
    component: ChapterComponent,
    activeroute: ActivatedRouteSnapshot,
    routestate: RouterStateSnapshot,
    routestateNext: RouterStateSnapshot
  ): boolean {
    // this is needed because we use chapter component even if chapterId is not suplied,
    // and redirect to first chapter than, and persistance is not needed for '' chapterId.
    if (activeroute.params.chapterId) {
      this.chapterStorageService.triggerPersistCurrentSurveyChapter();
      // In case of subscribe in chapter.component become aysnc, this lines should be used.
      // const pom = this.chapterStorageService.SurveyChapterPersistedObservable.pipe(first());
    }
    // return pom;
    return true;
  }
}
