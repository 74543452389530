import { Injectable } from '@angular/core';
import { NavChapter } from '../models/nav-chapter';
import { Subject } from 'rxjs';

@Injectable()
export class ChapterValidationService {
  constructor() {
  }

  public navChapterPersistanceChanged = new Subject<NavChapter>();
}
