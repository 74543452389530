import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AnonymizeService {

  constructor(private http: HttpClient) { }

  public anonymize(clientKey: string, dataKey) {
    return this.http.post(`api/anonymize`, {
      clientKey: clientKey,
      datakey: dataKey
    });
  }
}
