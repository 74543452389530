import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurveyService } from '../../services/survey.service';
import { NavChapter } from 'src/app/models/nav-chapter';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {
  chapters: NavChapter[] = new Array<NavChapter>();
  surveyId: string;

  constructor(
    private route: ActivatedRoute,
    private surveyService: SurveyService,
    private localStorageService: LocalStorageService,
    private spinnerService: SpinnerService) {
    }

  ngOnInit(): void {
    this.spinnerService.show("Wird geladen...");
    // the spinner will be hidden in the chapter component or finish component...
    // when chapters are loaded for the survey  

    this.route.params.subscribe(params => {
      const surveyId = params['surveyId'];
      const that = this;
      this.surveyService.getAllChaptersForNav(surveyId).subscribe(
        {
          next(chps) {
            that.chapters = chps;
          },
          error(error) {
            that.localStorageService.clearStorage();
          }
        }
      );
    });
  }
}
