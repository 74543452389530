import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  links: Links = {
    termsOfUse: 'https://krisen-check.de/nutzungsbedingungen/',
    //'https://xn--stabilittscheck-7kb.de/nutzungsbedingungen/',
    cookiePolicy: 'https://krisen-check.de/cookie-richtlinien/',
    //'https://xn--stabilittscheck-7kb.de/cookie-richtlinie/',
    privacyPolicy: 'https://krisen-check.de/datenschutzerklaerung/',
    declarationOfCancellation: 'https://krisen-check.de/widerrufsbelehrung/',
    //'https://xn--stabilittscheck-7kb.de/widerrufsbelehrung/',
    impressum: 'https://www.imb-hannover.de/impressum/'
  };
}


class Links {
  termsOfUse: string;
  cookiePolicy: string;
  privacyPolicy: string;
  declarationOfCancellation: string;
  impressum: string;
}
