import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  public clearStorage(): void {
    localStorage.clear();
  }
}
