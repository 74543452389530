import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  public showSpinnerSubject: Subject<string> = new Subject<string>();
  public hideSpinnerSubject: Subject<void> = new Subject<void>();

  public show(message: string) {
    this.showSpinnerSubject.next(message);
  }

  public hide() {
    this.hideSpinnerSubject.next();
  }

  constructor() { }
}
