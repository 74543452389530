import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnonymizeService } from 'src/app/services/anonymize.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-anonymize',
  templateUrl: './anonymize.component.html',
  styleUrls: ['./anonymize.component.css']
})
export class AnonymizeComponent implements OnInit {
  private clientKey = '';
  form: FormGroup;
  isAnonymizationFinished = false;
  myModal: ElementRef<any>;

  @ViewChild('myModal', {static: false})
  set myModalSetter(myModal: ElementRef | undefined) {
    this.myModal = myModal;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private anonymizeService: AnonymizeService,
  ) { }

  ngOnInit() {
    this.clientKey = this.route.snapshot.params.key;
    if (!this.clientKey) {
      this.router.navigate(['/']);
      return;
    } else {
      this.form = new FormGroup({
        data: new FormControl('', [Validators.required])
      });
    }
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  anonymize() {
    this.closeModal();
    this.isAnonymizationFinished = true;
    this.anonymizeService
      .anonymize(this.clientKey, this.form.controls.data.value)
      .subscribe();
  }

  openModal() {
    const el = this.myModal.nativeElement;
    el.setAttribute('style', 'display:block');
  }

  closeModal() {
    const el = this.myModal.nativeElement;
    el.setAttribute('style', 'display:none');
  }
}
