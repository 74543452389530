import { Component, Input, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NavChapter } from "src/app/models/nav-chapter";
import { ChapterValidationService } from "src/app/services/chapterValidation.service";
import { SurveyService } from "src/app/services/survey.service";
import { Survey } from "src/app/models/survey";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.css"]
})
export class NavMenuComponent implements OnInit {
  _chapters: NavChapter[] = new Array<NavChapter>();

  isAllValid: boolean = false;
  surveyName: string;
  survayId: string;
  isExpanded = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private chapterValidationService: ChapterValidationService,
    private surveyService: SurveyService
  ) {}

  @Input("chapters")
  set chapters(value: NavChapter[]) {
    if (value.length > 0) {
      this._chapters = value;
      this.chapterValidationService.navChapterPersistanceChanged.subscribe(
        (chapter: NavChapter) => {
          this.chapters.forEach(currentChapter => {
            if (currentChapter.id === chapter.id) {
              currentChapter.valid = chapter.valid;
              currentChapter.visited = chapter.visited;
            }
          });
        }
      );
    }
  }

  get chapters() {
    return this._chapters;
  }

  ngOnInit() {
    this.getSurveyName();
  }

  getSurveyName() {
    this.survayId = this.activatedRoute.snapshot.params.surveyId;
    this.surveyService
      .getSurvey(this.survayId)
      .subscribe((data: Survey) => (this.surveyName = data.name));
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
