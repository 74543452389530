import { Injectable } from '@angular/core';
import { Survey } from '../models/survey';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { SurveyType } from '../models/survey-type';
import { Chapter } from '../models/chapter';
import { NavChapter } from '../models/nav-chapter';
import { map, mergeMap, publishLast, refCount, debounceTime } from 'rxjs/operators';
import { SurveyStorageService } from './survey-storage.service';
import { SurveyInfo } from '../models/surveyInfo';
import { PersistedChapter } from '../models/persisted-chapter';
import { PersistedSurvey } from '../models/persisted-survey';

@Injectable()
export class SurveyService {
  constructor(private http: HttpClient, private surveyStorageService: SurveyStorageService) {
  }

  survey: Survey;
  surveyObservableMap = new Map<string, Observable<Survey>>();

  getAllSurveyInfo(): Observable<Array<SurveyInfo>> {
    return this.http.get<Array<SurveyInfo>>(`api/survey/allInfo`);
  }

  getSurvey(id: string): Observable<Survey> {
    if (this.survey) {
      return of(this.survey);
    } else {
      return this.getSurveyObservable(id);
    }
  }

  public checkIfExitsAndIsActive(id: string): Observable<void> {
    return this.http.get<void>(`api/survey/check-if-exists-and-is-active/${id}`);
  }

  public getSurveyObservable(id: string) {
    if (!this.surveyObservableMap.has(id)) {
      this.surveyObservableMap.set(
        id,
        this.http.get<Survey>(`api/survey/${id}`).pipe(
          map((data: Survey) => {
            this.survey = data;
            this.saveSurveyToLocalStorageIfNotSaved(data);
            return data;
          }),
          publishLast(),
          refCount()
        )
      );
    }
    return this.surveyObservableMap.get(id);
  }

  getMockSurvey(id: string): Observable<PersistedSurvey>{
    return this.http.get<PersistedSurvey>(`api/create-mock-data/${id}`);
  }

  getfirstChapterId(surveyId: string): Observable<string> {
    return this.getSurvey(surveyId).pipe(
      mergeMap((survey: Survey) => {
        if (survey.chapters.length > 0) {
          return of(survey.chapters[0].id);
        } else {
          return throwError('no chapters');
        }
      })
    );
  }

  private saveSurveyToLocalStorageIfNotSaved(survey: Survey) {
    if (!this.surveyStorageService.getPersistedSurvey(survey.id)) {
      survey.chapters.forEach((element: Chapter) => {
        const chapter: PersistedChapter = {
          id: element.id,
          questionAnswersCollection: [],
          visited: false,
          valid: false
        };
        this.surveyStorageService.saveSurvey(survey.id, chapter);
      });
    }
  }

  getAllChaptersForNav(id: string): Observable<Array<NavChapter>> {
    return this.getSurvey(id).pipe(
      map((survey: Survey) => {
        const navChapters = new Array<NavChapter>();
        survey.chapters.forEach(chapter => {
          const navChapter = new NavChapter();
          navChapter.id = chapter.id;
          navChapter.name = chapter.name;
          navChapter.position = chapter.position;
          navChapter.valid = false;
          navChapter.visited = false;
          const persistedChapter = this.surveyStorageService.getPersistedChapter(id, chapter.id);
          if (persistedChapter) {
            navChapter.valid = persistedChapter.valid;
            navChapter.visited = persistedChapter.visited;
          }
          navChapters.push(navChapter);
        });
        return navChapters;
      })
    );
  }

  getChapter(surveyId: string, chapterId: string): Observable<Chapter> {
    return this.getSurvey(surveyId).pipe(map((survey: Survey) => {
      return survey.chapters.find(c => c.id === chapterId);
    }
    ));
  }

  getNextChapter(surveyId: string, currentChapter: Chapter): Observable<Chapter> {
    const nextChapter: Chapter = this.survey.chapters.find(
      chapter => chapter.position > currentChapter.position
    );

    return nextChapter ? of(nextChapter) : of(currentChapter);
  }

  getPreviousChapter(questionnaireId: string, currentChapter: Chapter): Observable<Chapter> {
    const chapters = this.survey.chapters;
    let previousChapter: Chapter = null;

    for (let i = chapters.length - 1; i >= 0; i--) {
      if (chapters[i].position < currentChapter.position) {
        previousChapter = chapters[i];
        break;
      }
    }

    return previousChapter ? of(previousChapter) : of(currentChapter);
  }

  getSurveyTypes(): string[] {
    return [
      SurveyType.Big,
      SurveyType.Small
    ];
  }
}
