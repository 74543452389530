import { Component, OnInit } from '@angular/core';
import { SurveyService } from '../../services/survey.service';
import { SurveyInfo } from 'src/app/models/surveyInfo';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  availableSurveys$: Observable<SurveyInfo[]>;

  constructor(private surveyService: SurveyService) {
  }

  ngOnInit(): void {
    this.availableSurveys$ = this.surveyService.getAllSurveyInfo();
  }
}
